/**
 * Onboard quiz helpers
 * manages state for the whole form and other elements
 * note: x-wizard:step is set on elements in webflow directly for speed
 */

import { paths } from '../config';
import { identify, trackEvent } from '../services/analytics';
import { redirect } from '../utils/redirect';

export const quizHandler = (next) => ({
  redirectOnFinish: `${paths.signup}?next=${encodeURIComponent(
    '/join/all-access-trial'
  )}`,
  started: true,
  fitnessGoals: [],
  daysWorkingOut: null,
  gender: null,
  competitions: null,
  exerciseTypes: [],
  stepProgress: [],

  score: {
    gain: 0,
    train: 0,
    perform: 0,
    womens: 0,
    compete: 0,
    hyrox: 0,
  },
  init() {
    if (next) {
      this.redirectOnFinish = `${paths.signup}?next=${encodeURIComponent(
        next
      )}`;
    }
    this.$watch('score', (v) => console.log('score', v));
  },

  formatArray(ans) {
    if (ans === null || ans === undefined) {
      return undefined;
    }
    if (Array.isArray(ans)) {
      return ans;
    }
    return [ans];
  },
  getCurrentStepElements() {
    const current = this.$wizard.current();
    const currentQuestion = current.el.querySelector('h1')?.innerText;
    const currentForm = current.el.querySelector('form');

    return {
      el: current.el,
      question: currentQuestion,
      form: currentForm,
      step: this.$wizard.progress().current,
    };
  },

  async onSelect() {
    const { el, question, form, step } = this.getCurrentStepElements();
    const next = this.$wizard.next();
    const totalSteps = this.$wizard.progress().total;
    const isFinalStep = this.$wizard.isLast();

    if (this.$wizard.cannotGoForward() && !isFinalStep) {
      return;
    }
    const stepProgressKey = next?.el?.getAttribute('data-progress');

    if (stepProgressKey) {
      this.stepProgress.push(stepProgressKey);
    }

    const selectedOptions = form ? form.getAttribute('x-modelform') : null;
    const currentAnswer = selectedOptions ? this[selectedOptions] : null;

    this.addScores(el, currentAnswer);

    trackEvent('Onboarding step completed', {
      step,
      question,
      selectedOptions: this.formatArray(currentAnswer),
      pagePath: window.location.pathname,
      wizard_name: 'sep24',
    });

    if (currentAnswer) {
      identify({
        [`quiz_${selectedOptions}`]: currentAnswer,
      });
    }

    if (isFinalStep) {
      trackEvent('Onboarding wizard completed', {
        wizard_name: 'sep24',
        totalSteps,
        pagePath: window.location.pathname,
      });

      redirect(this.redirectOnFinish);

      return;
    }

    this.$wizard.canGoForward() && this.$wizard.forward();
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: This makes the scroll smooth
    });
  },

  addScores(currentEl, currentAnswer) {
    const elsWithScore = currentEl.querySelectorAll('[data-score]');

    if (elsWithScore.length < 1) {
      return;
    }
    // program scoring
    if (currentAnswer) {
      const answers =
        typeof currentAnswer === 'string' ? [currentAnswer] : currentAnswer;

      answers.forEach((a) => {
        const selectedInput = currentEl.querySelector(`input[value="${a}"]`);
        const elWithScore = this.findElementWithDataScore(selectedInput);

        const score = elWithScore
          ? elWithScore.getAttribute('data-score')
          : null;
        if (score) {
          const formatted = score.split(',').map((v) => v.trim());
          formatted.forEach((v) => {
            if (!Object.keys(this.score).includes(v)) {
              return;
            }
            this.score[v]++;
          });
        }
      });
    }
  },
  findElementWithDataScore(element) {
    // Check if the current element has data-score
    if (element.hasAttribute('data-score')) {
      return element;
    }

    // Traverse up to the parent
    const sibling = element.parentElement.querySelector('[data-score]');
    if (sibling) {
      return sibling;
    }

    // Return null if no element with data-score is found
    return null;
  },

  showProgram(program) {
    const filteredScores = Object.entries(this.score)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3)
      .map(([name]) => name);

    return filteredScores.includes(program);
  },

  next: {
    ['x-text']() {
      return this.$wizard.isLast() ? 'Get your program' : 'Continue';
    },
    ['@click.prevent']() {
      this.onSelect();
    },
    ['x-disabled']() {
      if (this.$wizard.isLast()) {
        return this.$wizard.isNotComplete();
      }
      return this.$wizard.cannotGoForward();
    },
  },

  skip: {
    ['@click.prevent']() {
      const { step, question } = this.getCurrentStepElements();
      trackEvent('Onboarding wizard skipped', {
        step,
        question,
      });
      redirect(this.redirectOnFinish);
    },
  },
});

import {
  getContext,
  getCampaignContext,
  getAnonymousId,
} from '../utils/context';
import { client } from './api';
const environment = process.env.NODE_ENV;

const getAnalyticsContext = () => {
  const { fbp, fbclid, fbc, gclid } = getContext();
  const referrer = document.referrer;
  const context = {
    campaign: getCampaignContext(),
    locale: navigator.language,
    userAgent: navigator.userAgent,
    page: {
      path: window.location.pathname,
      referrer: referrer,
      referring_domain: referrer ? document.referrer.split('/')[2] : '',
      search: window.location.search,
      title: document.title,
      url: window.location.href,
    },
    fbc: fbc || fbclid,
    fbp,
    gclid,
  };

  return context;
};

export const trackEvent = (name, properties) => {
  const context = getAnalyticsContext();
  const anonymousId = getAnonymousId();

  client
    .post('track/event', {
      json: {
        event: name,
        properties,
        context,
        anonymousId,
      },
      keepalive: true,
    })
    .catch((e) => {
      if (environment !== 'production') {
        console.log(e);
      }
    });
};

export const pageView = (name, properties) => {
  const context = getAnalyticsContext();
  const anonymousId = getAnonymousId();

  client
    .post('track/page', {
      json: {
        name,
        properties,
        context,
        anonymousId,
      },
      keepalive: true,
    })
    .catch((e) => {
      if (environment !== 'PRODUCTION') {
        console.log(e);
      }
    });
};

export const identify = (traits) => {
  const context = getAnalyticsContext();
  const anonymousId = getAnonymousId();

  client
    .post('track/identify', {
      json: {
        traits,
        context,
        anonymousId,
      },
      keepalive: true,
    })
    .catch((e) => {
      if (environment !== 'PRODUCTION') {
        console.log(e);
      }
    });
};

/**
 * x-modelform = state name to model to
 * A handy helper to model child inputs in a webflow form to a top level data
 * Webflow doesnt let you set value data attributes so this allows you to easily
 * set them all in a form component for all children
 */

export const modelform = (el, { expression }) => {
  const children = el.querySelectorAll(
    'input[type=checkbox], input[type=radio]'
  );
  el.removeAttribute('action');
  el.removeAttribute('method');

  children.forEach((input) => {
    input.value = input.id;
    input.setAttribute('x-model', expression);
  });
};
